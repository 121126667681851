import React from "react";
import StarRating from "./StarRating";
import { Link } from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image"

export default function Tips(props) {
  return (
    <Link to={props.link}>
      <div className="bookCard bg-gray-100">
      <div className="header">
        <GatsbyImage
          image={props.bookCover}
          className="image-card"
          alt={"boktips " + props.bookTitle}
          loading="lazy"
        />
        <div className="headerContent">
          <div className="header-title">
            <h2 className="font-bold text-2xl text-gray-900 leading-tight mb-8">{props.bookTitle}</h2>
            <h5 className="font-medium text-lg">{props.author}</h5>
          </div>
          <StarRating rating={props.rating} />
        </div>
      </div>
      </div>
    </Link>
  );
}
