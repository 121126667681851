import React from "react";
import {FaStar} from "react-icons/fa"

export default class StarRating extends React.Component {
  

  render() {
    const stars = [1,2,3,4,5]

    return (
    <div className="flex stars">
        {stars.map((star, i) => (
          <FaStar
            key={"star"+i}
            className={star <= Math.round(this.props.rating) ? "star yellowStar" : "star grayStar"}
          />
        ))}
    </div>)
  }
}
