import React, { useRef } from "react";
import Page from "../components/Page";
import Tips from "../components/Booktips/Tips";
import { graphql } from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image"
import Bookbeat from "../components/LinkCards/Bookbeat";
import "../components/Booktips/Booktips.scss";
import "../components/LinkCards/LinkCards.scss"

export default function BookTips({ data }) {
  const boktips = data.boktips.books;
  const ref = useRef(null);
  const scrollRef = () => ref.current.scrollIntoView({ behavior: "smooth" });

  return (
    <>
      <Page
        title="Boktips för personlig utveckling | Framgångspillret"
        description={
          "Mina bästa boktips inom personlig utveckling. Riktiga toppenböcker inom framgång, hälsa, produktivitet och personlig utveckling."
        }>
        <div className="relative">
          <div className="container m-auto absolute z-50 top-1/4 px-4 sm:px-8 lg:px-16 xl:px-20">
            <h1 className="font-bold text-3xl max-w-xl md:text-4xl md:max-w-2xl text-white leading-tight mb-4">
              Våra boktips
            </h1>
            <p className="text-lg max-w-lg text-white leading-tight mb-4">Några av mina favoritböcker inom personlig utveckling som jag starkt rekommenderar.</p>
          </div>
          <GatsbyImage
            image={data.image.childImageSharp.gatsbyImageData}
            className="h-96 w-full"
          />
        </div>
        <div className="container m-auto max-w-6xl py-16 px-4" ref={ref}>
            {boktips.map((tips, index) => {
              return (
                <Tips
                  key={tips.id}
                  author={tips.frontmatter.bookAuthor}
                  bookTitle={tips.frontmatter.title}
                  rating={tips.frontmatter.rating}
                  link={"/boktips/" + tips.frontmatter.pathname}
                  bookCover={tips.frontmatter.bookCover?.childImageSharp.gatsbyImageData}
                />
              );
            })}
            <Bookbeat />
        </div>
      </Page>
    </>
  );
}

export const pageQuery = graphql`
  query Boktips {
    boktips: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(/boktips/)/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      books: nodes {
        frontmatter {
          date
          title
          bookAuthor
          pathname
          rating
          tag
          author
          featuredImage {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
          bookCover {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
        excerpt
        id
      }
    }
    image: file(relativePath: { eq: "readingtips.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;
